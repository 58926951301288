<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Services Details</h1>
        <ul>
            <li><a routerLink="/">Home</a></li>
            <li>Services Details</li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area uk-services-details uk-section">
    <div class="uk-container">
        <article class="uk-article services-details">
            <div class="uk-grid uk-flex">
                <div class="inner uk-width-expand">
                    <div class="services-details-desc">
                        <h3>Nos Services</h3>
                        <p>En conclusion, chez Maku-Digital, nous avons pour mission de permettre aux entreprises de toutes tailles de prospérer dans le paysage numérique. Notre gamme complète de services s'étend de l'affichage publicitaire électronique au marketing numérique, garantissant que votre entreprise puisse atteindre son plein potentiel.</p>

                        <p>Qu'il s'agisse d'écrans publicitaires numériques innovants, d'options de location polyvalentes, de solutions de surveillance de pointe ou d'équipements et d'accessoires informatiques essentiels, nous nous efforçons de vous doter des outils nécessaires à votre réussite.</p>

                        <p>Avec notre engagement pour l'excellence et la satisfaction du client, nous sommes là pour vous soutenir à chaque étape de votre voyage vers la réalisation de vos objectifs dans le monde dynamique de l'entreprise numérique.</p>
                        <div class="services-image-slides owl-carousel owl-theme">
                            <div class="item">
                                <img src="assets/img/blog-details.webp" alt="img">
                            </div>

                            <div class="item">
                                <img src="assets/img/blog-details2.webp" alt="img">
                            </div>

                            <div class="item">
                                <img src="assets/img/blog-details3.webp" alt="img">
                            </div>

                            <div class="item">
                                <img src="assets/img/blog-details4.webp" alt="img">
                            </div>
                        </div>
                        <h3>Pourquoi choisir nos services ?</h3>
                        <p>Chez Maku-Digital, notre approche de la publicité sur écran électronique et du marketing numérique repose sur un processus dynamique et axé sur les résultats. Nous commençons par bien comprendre vos objectifs, votre public cible et votre marché.</p>

                        <ul class="services-features-list">
                            <li><i class="flaticon-tick"></i> Une technologie de pointe</li>
                            <li><i class="flaticon-tick"></i> Ingénieurs experts</li>
                            <li><i class="flaticon-tick"></i> Assistance 24/7</li>
                            <li><i class="flaticon-tick"></i> Livraison dans le délai prévu</li>
                            <li><i class="flaticon-tick"></i>  Architecture créative</li>
                        </ul>

                        <blockquote class="blockquote">
                            <p>L'innovation : Nous restons à l'avant-garde des tendances et des technologies du secteur afin de proposer des solutions nouvelles et efficaces.</p>
                        </blockquote>

                        <h3>Nos avantages professionnels</h3>
                        <p>Produits et services de qualité : Les clients bénéficient de services de haute qualité qui répondent à leurs attentes ou les dépassent. La qualité peut se rapporter à la durabilité, à la fonctionnalité et à la performance globale.</p>

                        <div class="our-work-benefits">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title active" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Économies budgétaires 
                                    </a>

                                    <p class="accordion-content show">De nombreux clients recherchent des solutions rentables. Ils bénéficient de prix compétitifs, de remises, de promotions et de programmes de fidélisation qui les aident à économiser de l'argent.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Personnalisation et sur mesure
                                    </a>

                                    <p class="accordion-content">Les clients apprécient la possibilité d'adapter les produits ou les services à leurs besoins et préférences spécifiques. Cette personnalisation ajoute de la valeur à l'expérience du client.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Expertise et connaissance 
                                    </a>

                                    <p class="accordion-content">Les clients bénéficient d'entreprises qui offrent des conseils d'experts, des orientations et des solutions qui répondent à leurs défis ou questions spécifiques.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        L'impact communautaire et social 
                                    </a>

                                    <p class="accordion-content">Les clients peuvent apprécier les entreprises qui sont socialement responsables et qui contribuent à la communauté ou soutiennent des causes sociales et environnementales.

                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="uk-sidebar uk-width-1-5 uk-flex-first@l uk-first-column">
                    <div class="widget widget_search">
                        <form>
                            <input type="text" class="uk-input" placeholder="Rechercher ici...">
                            <button type="submit"><i class="flaticon-search"></i></button>
                        </form>
                    </div>

                    <div class="widget service_list">
                        <ul>
                            <li><a routerLink="/service-details" class="active">Ecrans digital publicitaire sur RN39  <i class="flaticon-right"></i></a></li>
                            <li><a routerLink="/service-details">Location de la camionnette publicitaire <i class="flaticon-right"></i></a></li>
                            <li><a routerLink="/service-details">Location de sacs publicitaire<i class="flaticon-right"></i></a></li>
                            <li><a routerLink="/service-details">Location et vente des ecrans digital vertical et horizontal <i class="flaticon-right"></i></a></li>
                            <li><a routerLink="/service-details">Vente des cameras de serveillence <i class="flaticon-right"></i></a></li>
                            <li><a routerLink="/service-details">Montage des videos  <i class="flaticon-right"></i></a></li>
                            <li><a routerLink="/service-details">Vente et location des projecteurs photos <i class="flaticon-right"></i></a></li>
                            <li><a routerLink="/service-details">Vente des Lampadaires LED<i class="flaticon-right"></i></a></li>
                            <li><a routerLink="/service-details">Business cards and profile design <i class="flaticon-right"></i></a></li>
                            <li><a routerLink="/service-details">Routeur WIFI <i class="flaticon-right"></i></a></li>
                        </ul>
                    </div>

                    <div class="widget widget_download">
                        <h3 class="widget-title">Download</h3>
                        <div class="bar"></div>

                        <ul>
                            <li><a href="/assets/profile/maku-brochure.pdf" target="_blank">Notre brochure PDF <i class="flaticon-edit"></i></a></li>
                            <li><a href="/assets/profile/maku-profile.pdf" target="_blank">Notre profil d'entreprise PDF <i class="flaticon-edit"></i></a></li>
                            <li><a href="#">Great Technology <i class="flaticon-edit"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </article>
    </div>
</section>
<!-- End Services Details Area -->
