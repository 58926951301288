<!-- Start Main Banner -->
<div id="home" class="hero-banner">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="hero-banner-content">
                    <h1>We Provide Best SEO Services</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <div class="btn-box">
                        <a routerLink="/contact" class="uk-button uk-button-default">Get Started</a>
                        <a routerLink="/about" class="uk-button-optional">About Us</a>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="hero-banner-image uk-text-center">
                    <img src="assets/img/banner-illustration.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Main Banner -->

<div class="separate">
    <div class="br-line"></div>
</div>

<!-- Start About Area -->
<section id="about" class="uk-about about-area uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="about-image-two uk-text-center">
                    <img src="assets/img/about-us.png" alt="image">
                </div>
            </div>

            <div class="item">
                <div class="about-content">
                    <div class="uk-section-title section-title">
                        <span>About Us</span>
                        <h2>We Are Modern & Special For Designing</h2>
                        <div class="bar"></div>
                    </div>

                    <div class="about-text">
                        <div class="icon">
                            <i class="flaticon-quality"></i>
                        </div>
                        <h3>Best SEO Agency in the World</h3>
                        <p>We provide marketing services to startups and small businesses to looking for a partner of their digital media, design & development, lead generation and communications requirents. We work with you, not for you. Although we have a great resources.</p>
                        <p>We are an experienced and talented team of passionate consultants who live and breathe search engine marketing.</p>

                        <div class="signature">
                            <img src="assets/img/signature.png" alt="signature">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Area -->

<div class="separate">
    <div class="br-line"></div>
</div>

<!-- Start Services Area -->
<section id="services" class="uk-services services-section bg-image uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title uk-text-center">
            <span>What we do</span>
            <h2>Build Your Website, Let's Check Our Services</h2>
            <div class="bar"></div>
        </div>

        <div class="services-slides owl-carousel owl-theme">
            <div class="services-box">
                <img src="assets/img/services-img1.jpg" alt="image">

                <div class="content">
                    <div class="icon">
                        <i class="flaticon-plan"></i>
                    </div>
                    <h3>Digital Branding</h3>
                </div>

                <div class="hover-content">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="inner">
                                <div class="icon">
                                    <i class="flaticon-plan"></i>
                                </div>
                                <h3>Digital Branding</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                                <a routerLink="/service-details" class="details-btn"><i class="flaticon-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="services-box">
                <img src="assets/img/services-img2.jpg" alt="image">

                <div class="content">
                    <div class="icon">
                        <i class="flaticon-think"></i>
                    </div>
                    <h3>Creative Solutions</h3>
                </div>

                <div class="hover-content">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="inner">
                                <div class="icon">
                                    <i class="flaticon-think"></i>
                                </div>
                                <h3>Creative Solutions</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                                <a routerLink="/service-details" class="details-btn"><i class="flaticon-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="services-box">
                <img src="assets/img/services-img3.jpg" alt="image">

                <div class="content">
                    <div class="icon">
                        <i class="flaticon-shout"></i>
                    </div>
                    <h3>Marketing Solutions</h3>
                </div>

                <div class="hover-content">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="inner">
                                <div class="icon">
                                    <i class="flaticon-shout"></i>
                                </div>
                                <h3>Marketing Solutions</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                                <a routerLink="/service-details" class="details-btn"><i class="flaticon-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="services-box">
                <img src="assets/img/services-img2.jpg" alt="image">

                <div class="content">
                    <div class="icon">
                        <i class="flaticon-think"></i>
                    </div>
                    <h3>Creative Solutions</h3>
                </div>

                <div class="hover-content">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="inner">
                                <div class="icon">
                                    <i class="flaticon-think"></i>
                                </div>
                                <h3>Creative Solutions</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                                <a routerLink="/service-details" class="details-btn"><i class="flaticon-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->

<div class="separate">
    <div class="br-line"></div>
</div>

<!-- Start Why Choose Us Area -->
<section class="why-choose-us-section uk-why-choose-us uk-section">
    <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-2@s">
        <div class="item">
            <div class="why-choose-us-content">
                <div class="uk-section-title section-title">
                    <span>Why Choose Us</span>
                    <h2>We Are Dynamic & Special For SEO</h2>
                    <div class="bar"></div>
                </div>

                <ul class="why-choose-us-text">
                    <li>
                        <div class="icon">
                            <i class="flaticon-plan"></i>
                        </div>
                        <h3>Data Analysis</h3>
                        <p>We provide marketing services to startups and samll businesses to looking for a partner of their digital media, design & development, lead generation.</p>
                    </li>

                    <li>
                        <div class="icon">
                            <i class="flaticon-think"></i>
                        </div>
                        <h3>PPC Marketing</h3>
                        <p>We provide marketing services to startups and samll businesses to looking for a partner of their digital media, design & development, lead generation.</p>
                    </li>

                    <li>
                        <div class="icon">
                            <i class="flaticon-shout"></i>
                        </div>
                        <h3>Business Analytics</h3>
                        <p>We provide marketing services to startups and samll businesses to looking for a partner of their digital media, design & development, lead generation.</p>
                    </li>
                </ul>
            </div>
        </div>

        <div class="item">
            <div class="why-choose-us-image uk-text-center">
                <img src="assets/img/business-people.png" alt="image">
            </div>
        </div>
    </div>
</section>
<!-- End Why Choose Us Area -->

<div class="separate">
    <div class="br-line"></div>
</div>

<!-- Start Process Area -->
<section class="process-section bg-image uk-process uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title uk-text-center">
            <span>Process</span>
            <h2>Easy Ways To Get Ready Your Work</h2>
            <div class="bar"></div>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-4@m uk-child-width-1-2@s">
            <div class="item">
                <div class="single-process-box">
                    <div class="icon">
                        <i uk-icon="receiver"></i>
                    </div>
                    <h3>Contact Us First</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                </div>
            </div>

            <div class="item">
                <div class="single-process-box">
                    <div class="icon">
                        <i uk-icon="cog"></i>
                    </div>
                    <h3>Consult With Us</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                </div>
            </div>

            <div class="item">
                <div class="single-process-box">
                    <div class="icon">
                        <i uk-icon="cart"></i>
                    </div>
                    <h3>Place Order</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                </div>
            </div>

            <div class="item">
                <div class="single-process-box">
                    <div class="icon">
                        <i uk-icon="home"></i>
                    </div>
                    <h3>Give Us Payment!</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                </div>
            </div>

            <div class="item">
                <div class="process-arrow-icon">
                    <img src="assets/img/arrow.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Process Area -->

<div class="separate">
    <div class="br-line"></div>
</div>

<!-- Start Team Area -->
<section id="team" class="team-area uk-team uk-section">
    <div class="uk-container">
        <div class="uk-section-title uk-text-center section-title">
            <span>Our Experts</span>
            <h2>Meet Our Leadership Preparing For Your Success</h2>
            <div class="bar"></div>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="item">
                <div class="single-team-box">
                    <img src="assets/img/team8.jpg" alt="image">

                    <div class="content">
                        <h3>James Anderson</h3>
                        <span>Local SEO</span>

                        <div class="social">
                            <div class="social-btn">
                                <span uk-icon="cog"></span>
                            </div>
                            <ul>
                                <li><a href="#"><i class="flaticon-logo"></i></a></li>
                                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                                <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="single-team-box">
                    <img src="assets/img/team7.jpg" alt="image">

                    <div class="content">
                        <h3>Lee Munroe</h3>
                        <span>CEO & Founder</span>

                        <div class="social">
                            <div class="social-btn">
                                <span uk-icon="cog"></span>
                            </div>
                            <ul>
                                <li><a href="#"><i class="flaticon-logo"></i></a></li>
                                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                                <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="single-team-box">
                    <img src="assets/img/team6.jpg" alt="image">

                    <div class="content">
                        <h3>Calvin Klein</h3>
                        <span>Marketing Manager</span>

                        <div class="social">
                            <div class="social-btn">
                                <span uk-icon="cog"></span>
                            </div>
                            <ul>
                                <li><a href="#"><i class="flaticon-logo"></i></a></li>
                                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                                <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Team Area -->

<!-- Start Funfacts Area -->
<section class="funfacts-area uk-section uk-funfacts">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-4@m uk-child-width-1-2@s">
            <div class="item">
                <div class="single-funfacts">
                    <div class="icon">
                        <i uk-icon="users"></i>
                    </div>
                    <h3 class="odometer" data-count="1800">00</h3>
                    <p>Satisfied Clients</p>
                </div>
            </div>

            <div class="item">
                <div class="single-funfacts">
                    <div class="icon">
                        <i uk-icon="heart"></i>
                    </div>
                    <h3 class="odometer" data-count="2500">00</h3>
                    <p>Completed Projects</p>
                </div>
            </div>

            <div class="item">
                <div class="single-funfacts">
                    <div class="icon">
                        <i uk-icon="user"></i>
                    </div>
                    <h3 class="odometer" data-count="250">00</h3>
                    <p>Team Members</p>
                </div>
            </div>

            <div class="item">
                <div class="single-funfacts">
                    <div class="icon">
                        <i uk-icon="unlock"></i>
                    </div>
                    <h3 class="odometer" data-count="150">00</h3>
                    <p>Award Winners</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Funfacts Area -->

<!-- Start Feedback Area -->
<section id="testimonials" class="feedback-section uk-section">
    <div class="uk-container">
        <div class="uk-grid" uk-grid>
            <div class="uk-width-1-3@m">
                <div class="uk-section-title section-title">
                    <span>Testimonials</span>
                    <h2>What Clients Say About Us</h2>
                    <div class="bar"></div>
                </div>
            </div>

            <div class="uk-width-expand@m">
                <div class="feedback-slides-two owl-carousel owl-theme">
                    <div class="single-feedback-item">
                        <i class="flaticon-quote"></i>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        <div class="client-info">
                            <img src="assets/img/client1.png" alt="image">
                            <h3>Jason Statham</h3>
                            <span>CTO at Envato</span>
                        </div>
                    </div>

                    <div class="single-feedback-item">
                        <i class="flaticon-quote"></i>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        <div class="client-info">
                            <img src="assets/img/client2.png" alt="image">
                            <h3>Sarah Taylor</h3>
                            <span>CTO at EnvyTheme</span>
                        </div>
                    </div>

                    <div class="single-feedback-item">
                        <i class="flaticon-quote"></i>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        <div class="client-info">
                            <img src="assets/img/client3.png" alt="image">
                            <h3>Alister Cook</h3>
                            <span>CTO at Envato</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>                
    </div>
</section>
<!-- End Feedback Area -->

<div class="separate">
    <div class="br-line"></div>
</div>

<!-- Start Blog Area -->
<section id="blog" class="blog-area uk-blog uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title uk-text-center">
            <span>Our Company Blog</span>
            <h2>Latest News</h2>
            <div class="bar"></div>
        </div>

        <div class="blog-slides owl-carousel owl-theme">
            <div class="single-blog-post">
                <div class="blog-post-image">
                    <a routerLink="/blog-details">
                        <img src="assets/img/blog1.jpg" alt="image">
                    </a>
                </div>

                <div class="blog-post-content">
                    <span class="date">25 April</span>
                    <h3><a routerLink="/blog-details">The 13 Best Time Tracking Apps of 2023</a></h3>
                    <a routerLink="/blog-details" class="read-more">Read More</a>
                </div>
            </div>

            <div class="single-blog-post">
                <div class="blog-post-image">
                    <a routerLink="/blog-details">
                        <img src="assets/img/blog2.jpg" alt="image">
                    </a>
                </div>

                <div class="blog-post-content">
                    <span class="date">26 April</span>
                    <h3><a routerLink="/blog-details">11 Tools to Help You Easily Create Proposals</a></h3>
                    <a routerLink="/blog-details" class="read-more">Read More</a>
                </div>
            </div>

            <div class="single-blog-post">
                <div class="blog-post-image">
                    <a routerLink="/blog-details">
                        <img src="assets/img/blog3.jpg" alt="image">
                    </a>
                </div>

                <div class="blog-post-content">
                    <span class="date">27 April</span>
                    <h3><a routerLink="/blog-details">The Outlook for Digital Agencies in 4 Charts</a></h3>
                    <a routerLink="/blog-details" class="read-more">Read More</a>
                </div>
            </div>

            <div class="single-blog-post">
                <div class="blog-post-image">
                    <a routerLink="/blog-details">
                        <img src="assets/img/blog1.jpg" alt="image">
                    </a>
                </div>

                <div class="blog-post-content">
                    <span class="date">25 April</span>
                    <h3><a routerLink="/blog-details">The 13 Best Time Tracking Apps of 2023</a></h3>
                    <a routerLink="/blog-details" class="read-more">Read More</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Blog Area -->

<!-- Start Partner Area -->
<div class="partner-area uk-section uk-padding-remove-top">
    <div class="uk-container">
        <div class="partner-slides owl-carousel owl-theme">
            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-one.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-two.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-three.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-four.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-five.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-six.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</div>
<!-- End Partner Area -->