<!-- Start Woman Banner Area -->
<div class="uk-woman-banner woman-main-banner-area">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="uk-item uk-flex-middle">
                <div class="woman-banner-content">
                    <span>FAITE VOS PUBLICIT&Eacute;S SOUS UNE AUTRE DIMENSION</span>
                    <h1>Maku-Digital</h1>
                    <p>Des panneaux d&apos;affichage num&eacute;riques dynamiques et attrayants</p>
                    <p>Des campagnes cibl&eacute;es pour atteindre votre public id&eacute;al.</p>

                    <ul class="banner-btn-list">
                        <li>
                            <a href="/contact" class="uk-button uk-button-default">Démarrage</a>
                        </li>
                        <li>
                            <a href="/about" class="uk-button uk-button-default">À propos de nous</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="uk-item">
                <div class="woman-banner-image">
                    <img src="assets/img/home-seven/banner/wonan.webp" alt="image">
                </div>
            </div>
        </div>
    </div>

    <ul class="woman-banner-social">
        <li>
            <span>Dites bonjour</span>
        </li>
        <li><a href="https://www.facebook.com/Makudigital"><i class="flaticon-logo"></i></a></li>
        <li><a href="#"><i class="flaticon-twitter"></i></a></li>
        <li><a href="http://instagram.com/Makudigital"><i class="flaticon-logo-1"></i></a></li>
    </ul>
</div>
<!-- End Woman Banner Area -->

<!-- Start Creative About Area -->
<div class="uk-creative-about creative-about-area ptb-100">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="uk-item">
                <div class="creative-about-left-content">
                    <div class="big-text">&Agrave; propos de nous</div>
                    <span>&Agrave; propos de nous</span>
                    <h3>Une agence de marketing cr&eacute;ative &agrave; la pointe de la technologiels
                    </h3>
                    <p>Chez Maku-Digital, notre approche de la publicité sur écran électronique et du marketing numérique repose sur un processus dynamique et axé sur les résultats. Nous commençons par bien comprendre vos objectifs, votre public cible et votre marché.</p>

                    <div class="about-left-image">
                        <img src="assets/img/home-seven/about/about1.webp" alt="image">

<!--                        <div class="content">-->
<!--                            <h4>15</h4>-->
<!--                            <b>Years Experience</b>-->
<!--                        </div>-->
                    </div>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-about-right-content">
                    <div class="about-right-image">
                        <img src="assets/img/home-eight/about.webp" alt="image">

<!--                        <div class="content">-->
<!--                            <h4>99%</h4>-->
<!--                            <b>Projects Done</b>-->
<!--                        </div>-->
                    </div>
                    <h3>Nous sommes les experts en marketing dont vous avez besoin pour votre entreprise</h3>
                    <p>Nous fournissons des services de marketing aux startups et aux petites entreprises &agrave; la recherche d&apos;un partenaire pour leurs m&eacute;dias num&eacute;riques, la conception et le d&eacute;veloppement, la g&eacute;n&eacute;ration de leads et les requ&ecirc;tes de communication. Nous travaillons avec vous, pas pour vous. Bien que nous disposions d&apos;excellentes ressources.</p>
                    <div class="about-right-btn">
                        <a href="/services" class="uk-button uk-button-default">Lire la suite</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Creative About Area -->

<!-- Start Creative Services Area -->
<div class="uk-creative-services creative-services-area pt-100 pb-70">
    <div class="uk-container">
        <div class="section-title-with-big-text">
            <div class="big-text">Nos Activit&eacute;s</div>
            <span>NOS SERVICES</span>
            <h2>Tous les services que nous fournissons pour r&eacute;pondre aux besoins commerciaux des clients</h2>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="uk-item">
                <div class="creative-services-box">
                    <div class="content">
                        <div class="number">
                            <span>01</span>
                        </div>
                        <h3>
                            <a href="/services">Diffusion Logo ou Image</a>
                        </h3>
                        <p>nous comprenons l&apos;importance d&apos;un logo convaincant et m&eacute;morable pour &eacute;tablir une identit&eacute; de marque forte. Notre service de conception de logo est bas&eacute; sur la cr&eacute;ativit&eacute;, la strat&eacute;gie et une compr&eacute;hension profonde de l&apos;essence de votre marque.</p>
                    </div>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-services-box">
                    <div class="content">
                        <div class="number two">
                            <span>02</span>
                        </div>
                        <h3>
                            <a href="/services">Conception video Pub</a>
                        </h3>
                        <p>Dans l&apos;&egrave;re num&eacute;rique actuelle, le contenu vid&eacute;o est roi, et notre service de production vid&eacute;o est con&ccedil;u pour vous aider &agrave; tirer parti de sa puissance de mani&egrave;re efficace. De la conception &agrave; l&apos;ex&eacute;cution, nous fournissons des solutions de production vid&eacute;o de bout en bout.</p>
                    </div>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-services-box">
                    <div class="content">
                        <div class="number three">
                            <span>03</span>
                        </div>
                        <h3>
                            <a href="/services">D&eacute;veloppement de contenu cr&eacute;atif</a>
                        </h3>
                        <p>1. Un contenu visuel et &eacute;crit convaincant.<br/>2. Adapt&eacute; &agrave; votre marque et &agrave; votre public.<br/>3. Vid&eacute;os, graphiques et textes de haute qualit&eacute;.</p>
                    </div>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-services-box">
                    <div class="content">
                        <div class="number four">
                            <span>04</span>
                        </div>
                        <h3>
                            <a href="/services">D&eacute;veloppement Web</a>
                        </h3>
                        <p>Découvrez la puissance de Maku Digital ! Améliorez votre présence en ligne grâce à nos services experts de développement web, et restez connecté en déplacement grâce à nos solutions d'applications mobiles de pointe. Laissez-nous donner vie à votre vision dans le monde numérique !</p>
                    </div>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-services-box">
                    <div class="content">
                        <div class="number five">
                            <span>05</span>
                        </div>
                        <h3>
                            <a href="/services">Fournisseur d'ordinateurs portables & Cartouches d'imprimante</a>
                        </h3>
                        <p>Explorez le monde des ordinateurs portables chez Maku-digital. Nous avons des options haut de gamme de grandes marques, garantissant l'accès aux dernières technologies. Que ce soit pour le travail, les loisirs ou la créativité, nous avons la solution parfaite. Qualité, prix abordable et conseils d'experts définissent notre engagement envers l'excellence. Découvrez notre gamme dès aujourd'hui et trouvez l'ordinateur portable idéal.</p>
                    </div>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-services-box">
                    <div class="content">
                        <div class="number six">
                            <span>06</span>
                        </div>
                        <h3>
                            <a href="/services">Des solutions cr&eacute;atives</a>
                        </h3>
                        <p>Chez Maku Digital, la créativité n'a pas de limites. Notre équipe est spécialisée dans l'élaboration de solutions innovantes adaptées à vos besoins uniques. Qu'il s'agisse de stratégies de marque originales, de création de contenu engageant ou de concepts de design visionnaires, nous sommes là pour transformer vos idées en réalité. Embarquons ensemble pour un voyage créatif !</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Creative Services Area -->

<!-- Start Creative Projects Area -->
<div class="uk-creative-projects creative-projects-area ptb-100">
    <div class="uk-container">
        <div class="section-title-with-big-text">
            <div class="big-text">LISTE DES PRODUITS</div>
            <span>Liste de services</span>
            <h2>Jetez un coup d&apos;&oelig;il &agrave; tous les projets que nous avons r&eacute;alis&eacute;s pour le client</h2>
        </div>
    </div>

    <div class="uk-container-expand">
        <div class="creative-projects-slides owl-carousel owl-theme">
            <div class="creative-single-projects">
                <div class="projects-image">
                    <a href="/service-details">
                        <img src="assets/img/home-seven/projects/projects1.webp" alt="image">
                    </a>
                </div>
                <div class="projects-content">
                    <span>Atteignez de nouveaux sommets de visibilité</span>
                    <h3>
                        <a href="/service-details">Location De La Camionnette Publicitaire </a>
                    </h3>
                </div>
                <div class="projects-icon">
                    <a href="/service-details"><i class="flaticon-right"></i></a>
                </div>
            </div>

            <div class="creative-single-projects">
                <div class="projects-image">
                    <a href="/service-details">
                        <img src="assets/img/home-seven/projects/projects2.webp" alt="image">
                    </a>
                </div>
                <div class="projects-content">
                    <span>MONTAGE DES VIDEOS</span>
                    <h3>
                        <a href="/service-details">De tout vos publicités</a>
                    </h3>
                </div>
                <div class="projects-icon">
                    <a href="/service-details"><i class="flaticon-right"></i></a>
                </div>
            </div>

            <div class="creative-single-projects">
                <div class="projects-image">
                    <a href="/service-details">
                        <img src="assets/img/home-seven/projects/projects3.webp" alt="image">
                    </a>
                </div>
                <div class="projects-content">
                    <span>FOUNITURES DES MATERIEL INFORMATIQUES</span>
                    <h3>
                        <a href="/service-details"><ul>
                            <li>Ventes des ordinateurs</li>
                            <li>Cartouches imprimantes</li>
                            <li>Pointeurs</li>
                            <li>Serveurs</li>
                            <li>Logiciels</li>
                            <li>Copieur</li>
                            <li>Accessoires et tablettes</li>
                        </ul></a>
                    </h3>
                </div>
                <div class="projects-icon">
                    <a href="/service-details"><i class="flaticon-right"></i></a>
                </div>
            </div>

            <div class="creative-single-projects">
                <div class="projects-image">
                    <a href="/service-details">
                        <img src="assets/img/home-seven/projects/projects4.webp" alt="image">
                    </a>
                </div>
                <div class="projects-content">
                    <span>En Location</span>
                    <h3>
                        <a href="/service-details">Box Signs Boîte à lumière portative à LED pour sac à dos </a>
                    </h3>
                </div>
                <div class="projects-icon">
                    <a href="/service-details"><i class="flaticon-right"></i></a>
                </div>
            </div>

            <div class="creative-single-projects">
                <div class="projects-image">
                    <a href="/service-details">
                        <img src="assets/img/home-seven/projects/projects55.webp" alt="image">
                    </a>
                </div>
                <div class="projects-content">
                    <span>En Location</span>
                    <h3>
                        <a href="/service-details">Ecrans Digital Publicitaire Sur RN39</a>
                    </h3>
                </div>
                <div class="projects-icon">
                    <a href="/service-details"><i class="flaticon-right"></i></a>
                </div>
            </div>

            <div class="creative-single-projects">
                <div class="projects-image">
                    <a href="/service-details">
                        <img src="assets/img/home-seven/projects/projects6.webp" alt="image">
                    </a>
                </div>
                <div class="projects-content">
                    <span>En Vente</span>
                    <h3>
                        <a href="/service-details">Vente Des Cameras De Serveillence Et Router</a>
                    </h3>
                </div>
                <div class="projects-icon">
                    <a href="/service-details"><i class="flaticon-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Creative Projects Area -->

<!-- Start Creative Reviews Area -->
<div class="uk-creative-reviews creative-reviews-area pb-100">
    <div class="uk-container">
        <div class="section-title-with-big-text">
            <div class="big-text">Revue de presse</div>
            <span>Témoignages</span>
            <h2>Ce que les clients disent de nous</h2>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="creative-reviews-img">
                    <img src="assets/img/home-seven/reviews.webp" alt="image">

                    <div class="icon">
                        <i class="flaticon-quote"></i>
                    </div>
                </div>
            </div>

            <div class="item uk-flex-middle">
                <div class="creative-reviews-slides owl-carousel owl-theme">
                    <div class="creative-reviews-card">
                        <ul class="rating">
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                        </ul>
                        <p>Nous utilisons les services d'affichage publicitaire de Maku Digital pour notre entreprise locale, et c'est une excellente expérience. L'équipe de Maku Digital nous a aidés à choisir des emplacements stratégiques pour nos panneaux d'affichage et nous avons constaté une augmentation notable du trafic piétonnier. Les designs créatifs et les messages clairs sur les panneaux ont permis de captiver l'attention de notre public cible.</p>

                        <div class="client">
                            <h3>Maman Fifi Masuka Saini</h3>
                            <span>Gouverneur du Lualaba</span>
                        </div>
                    </div>

                    <div class="creative-reviews-card">
                        <ul class="rating">
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                        </ul>
                        <p>La Direction des Recettes du Lualaba salue cette initiative de MAKU DIGITAL qui non seulement l’accompagne dans une certaine mesure à la sensibilisation des contribuables mais participe aussi à travers ces panneaux lumineux l’embellissement de grandes artères de la ville de Kolwezi.</p>
                        <p>Avec comme mission la mobilisation et la maximisation des Recettes, la DRLU accompagne le Gouvernement Provincial du Lualaba que dirige de main de maitre Son Excellence Madame Fifi MASUKA SAINI, Gouverneure de Province, qui à travers ses multiples réalisations dans différents secteurs de la vie socio-économique s’inscrivent fidèlement dans la vision du Chef de l’Etat, Président de la République, Son Excellence Felix Antoine TSHISEKEDI TSHILOMBO.</p>
                        <p>Ces réalisations, à travers plus de deux cents projets sont tellement palpables et visibles que la Régie financière Provinciale invite toute la population Lualabaise à d’avantage soutenir et accompagner le Gouvernement Provincial en s’acquittant de son devoir civique et patriotique en payant par voie bancaire ses taxes et impôts.</p>
                        <p></p>

                        <div class="client">
                            <h3>Le Directeur de la DRLU</h3>
                            <span>Francis KALASSA MANIKA</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Creative Reviews Area -->

<!-- Start Creative Partner Area -->
<div class="uk-creative-partner creative-partner-area with-bg-color ptb-100">
    <div class="uk-container">
        <div class="section-title-with-big-text">
            <div class="big-text">Partenaires</div>
            <span>Des clients pr&eacute;cieux</span>
            <h2>Voir nos clients les plus précieux de l'industrie</h2>
        </div>

        <div class="creative-partner-slides owl-carousel owl-theme">
            <div class="creative-partner-item">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner1.webp" alt="image">
                </a>
            </div>

            <div class="creative-partner-item">
                <a href="/contact">
                    <img src="assets/img/home-seven/partner/partner2.webp" alt="image">
                </a>
            </div>

            <div class="creative-partner-item">
                <a href="https://www.kbm-rdc.com" target="_blank">
                    <img src="assets/img/home-seven/partner/partner3.png" alt="image">
                </a>
            </div>            

            <div class="creative-partner-item">
                <a href="/contact">
                    <img src="assets/img/home-seven/partner/partner4.png" alt="image">
                </a>
            </div>

            <div class="creative-partner-item">
                <a href="/contact">
                    <img src="assets/img/home-seven/partner/partner5.webp" alt="image">
                </a>
            </div>

            <div class="creative-partner-item">
                <a href="/contact">
                    <img src="assets/img/home-seven/partner/partner6.png" alt="image">
                </a>
            </div>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</div>
<!-- End Creative Partner Area -->

<!-- Start Creative News Area -->
<section class="uk-creative-video creative-video-area">
    <div class="uk-container">
        <div class="creative-video-image">
            <img src="assets/img/home-nine/video.webp" alt="image">
            <a href="https://www.youtube.com/watch?v=Rr26ONzUBMM" class="video-btn popup-youtube"><i class='bx bx-play'></i></a>
        </div>
    </div>
</section>
<!-- End Creative News Area -->

<!-- Start Creative Contact Area -->
<div class="uk-creative-contact creative-contact-area ptb-100">
    <div class="uk-container">
        <div class="section-title-with-big-text">
            <div class="big-text">Contactez-Nous</div>
            <span>PARLONS-EN!</span>
            <h2>Vous voulez travailler avec notre équipe ?</h2>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="creative-contact-image">
                    <img src="assets/img/home-seven/contact.webp" alt="image">

                    <div class="info-content">
                        <h3>NOTRE BUREAU</h3>
                        <span>N&deg;999 de l&apos;avenue rn 39, quartier joli site Commune de Manika, &agrave; Kolwezi</span>
                    </div>
                </div>
            </div>

            <div class="item uk-flex-middle">
                <form class="creative-contact-form">
                    <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
                        <div class="item uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Nom</label>
                            <input type="text" class="uk-input" placeholder="John Kabulo">
                        </div>

                        <div class="item uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Courriel</label>
                            <input type="email" class="uk-input" placeholder="jkabulo@gmail.com">
                        </div>

                        <div class="item uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">T&eacute;l&eacute;phone</label>
                            <input type="text" class="uk-input" placeholder="+243-541-754-301">
                        </div>

                        <div class="item uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Sujet</label>
                            <input type="text" class="uk-input" placeholder="Votre sujet ici">
                        </div>
                    </div>

                    <div class="item">
                        <label class="uk-form-label" for="form-stacked-text">Votre message</label>
                        <textarea class="uk-textarea" cols="30" rows="4" placeholder="Écrivez votre message ici..."></textarea>
                    </div>

                    <div class="checkbox-boxes">
                        <label><input class="uk-checkbox" type="checkbox"> En cochant cette case, vous acceptez notre <a href="#">Conditions</a> & <a href="#">Politique de confidentialité</a></label>
                    </div>

                    <button type="submit" class="uk-button uk-button-default">Soumettre un message</button>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- End Creative Contact Area -->
