<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Services</h1>
        <ul>
            <li><a routerLink="/">Accueil</a></li>
            <li>Services</li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start Services Area -->
<section class="services-area uk-services uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <img src="assets/img/billboard_4123245.svg" alt="Flaticon Icon" class="flaticon-icon">
                    </div>
                    <h3>Ecrans Digital Publicitaire Sur RN39</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <img src="assets/img/cargo-truck_936784.svg" alt="Flaticon Icon" class="flaticon-icon">
                    </div>
                    <h3>Location De La Camionnette Publicitaire</h3>
                    <i class="flaticon-right link-btn"></i>
                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>
            

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <img src="assets/img/vulnerability_3045867.svg" alt="Flaticon Icon" class="flaticon-icon">
                    </div>
                    <h3>Location de sacs publicitaire </h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <img src="assets/img/glass_2238972.svg" alt="Flaticon Icon" class="flaticon-icon">
                    </div>
                    <h3>Location et vente des ecrans digital vertical et horizontal </h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services active">
                    <div class="icon">
                        <img src="assets/img/laptop-screen_4218347.svg" alt="Flaticon Icon" class="flaticon-icon">
                    </div>
                    <h3>Founitures des materiel informatiques </h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <img src="assets/img/video-editing_12776700.svg" alt="Flaticon Icon" class="flaticon-icon">
                    </div>
                    <h3>Montage des videos</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <img src="assets/img/photo-camera_8630043.svg" alt="Flaticon Icon" class="flaticon-icon">
                    </div>
                    <h3>Vente et location des projecteurs photos </h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <img src="assets/img/road-lamps_14013073.svg" alt="Flaticon Icon" class="flaticon-icon">
                    </div>
                    <h3>Vente des Lampadaires LED </h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <img src="assets/img/business-card_9724747.svg" alt="Flaticon Icon" class="flaticon-icon">
                    </div>
                    <h3>Business cards and profile design </h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <img src="assets/img/coding_8281298.svg" alt="Flaticon Icon" class="flaticon-icon">
                    </div>
                    <h3>Développement web et applications mobiles</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <img src="assets/img/wifi-router_2876838.svg" alt="Flaticon Icon" class="flaticon-icon">
                    </div>
                    <h3>Routeur WIFI </h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <img src="assets/img/typography_9201464.svg" alt="Flaticon Icon" class="flaticon-icon">
                    </div>
                    <h3>conception du logo</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->
