<!-- Start Footer Area -->
<footer class="footer-area {{location == '/' ? '' : 'uk-dark'}} uk-footer">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-4@m uk-child-width-1-2@s">
            <div class="item">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/">
                            <img src="assets/img/logo2.png" alt="logo">
                        </a>
                    </div>
                    <p>Chez Maku-Digital, nous pensons que la reussite dans le monde numerique est a la portee de toutes les entreprises. Nous nous engageons a vous aider a exploiter tout le potentiel de la publicite sur ecran electronique et du marketing numerique pour atteindre vos objectifs. </p>
                </div>
            </div>

            <div class="item">
                <div class="single-footer-widget">
                    <h3>Lubumbashi</h3>
                    <div class="bar"></div>

                    <div class="location">
                        <p>To be confirmed<br> </p>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="single-footer-widget">
                    <h3>Kolwezi</h3>
                    <div class="bar"></div>

                    <div class="location">
                        <p>N°999 de l'avenue rn 39, quartier  <br> joli site Commune de Manika, à Kolwezi, En République démocratique du Congo</p>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="single-footer-widget">
                    <h3>Contact</h3>
                    <div class="bar"></div>

                    <ul class="contact-info">
                        <li><a href="mailto:info@makudigital.com">info@makudigital.com</a></li>
                        <li><a href="https://www.makudigital.com/">www.makudigital.com</a></li>
                        <li><a href="tel:+243858585822">+243858585822</a></li>
                    </ul>
                    <ul class="social">
                        <li><a href="https://www.facebook.com/Makudigital" target="_blank"><i class="flaticon-logo"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-linkedin"></i></a></li>
                        <li><a href="http://instagram.com/Makudigital" target="_blank"><i class="flaticon-logo-1"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-2@s">
                <div class="item">
                    <p>© Maku Digital | Multi-media & Digital Marketing  </p>
                </div>

                <div class="item">
                    <ul>
                        <li><a href="#">Terms & Conditions</a></li>
                        <li><a href="#">Privacy Policy</a></li>
                    </ul>
                </div>
            </div>
            <div class="go-top"><i class="flaticon-chevron"></i></div>
        </div>
    </div>

    <div class="br-line"></div>
    <div class="footer-shape1"><img src="assets/img/footer-shape1.png" alt="shape"></div>
    <div class="footer-shape2"><img src="assets/img/footer-shape2.png" alt="shape"></div>
</footer>
<!-- End Footer Area -->
