<!-- Start Main Banner -->
<div id="home" class="banner-section">
    <div class="uk-container-expand">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="banner-content">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="content">
                                <h1>The best Angular Template for a <span>Startup</span></h1>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                                <div class="btn-box">
                                    <a routerLink="/contact" class="uk-button uk-button-default">Get Started</a>
                                    <a routerLink="/about" class="uk-button-optional">About Us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="banner-image">
                    <img src="assets/img/banner-img1.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Main Banner -->

<!-- Start Services Area -->
<section class="uk-services services-section uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title uk-text-center">
            <span>What we do</span>
            <h2>We are modern & special for designing</h2>
            <div class="bar"></div>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="item">
                <div class="services-box">
                    <img src="assets/img/services-img1.jpg" alt="image">

                    <div class="content">
                        <div class="icon">
                            <i class="flaticon-plan"></i>
                        </div>
                        <h3>Digital Branding</h3>
                    </div>

                    <div class="hover-content">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <div class="inner">
                                    <div class="icon">
                                        <i class="flaticon-plan"></i>
                                    </div>
                                    <h3>Digital Branding</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                                    <a routerLink="/service-details" class="details-btn"><i class="flaticon-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="services-box">
                    <img src="assets/img/services-img2.jpg" alt="image">

                    <div class="content">
                        <div class="icon">
                            <i class="flaticon-think"></i>
                        </div>
                        <h3>Creative Solutions</h3>
                    </div>

                    <div class="hover-content">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <div class="inner">
                                    <div class="icon">
                                        <i class="flaticon-think"></i>
                                    </div>
                                    <h3>Creative Solutions</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                                    <a routerLink="/service-details" class="details-btn"><i class="flaticon-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="services-box">
                    <img src="assets/img/services-img3.jpg" alt="image">

                    <div class="content">
                        <div class="icon">
                            <i class="flaticon-shout"></i>
                        </div>
                        <h3>Marketing Solutions</h3>
                    </div>

                    <div class="hover-content">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <div class="inner">
                                    <div class="icon">
                                        <i class="flaticon-shout"></i>
                                    </div>
                                    <h3>Marketing Solutions</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                                    <a routerLink="/service-details" class="details-btn"><i class="flaticon-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->

<div class="separate">
    <div class="br-line"></div>
</div>

<!-- Start About Area -->
<section id="about" class="uk-about about-area uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="about-image">
                    <img src="assets/img/about3.jpg" alt="image">
                    <img src="assets/img/about4.jpg" alt="image">
                </div>
            </div>

            <div class="item">
                <div class="about-content">
                    <div class="uk-section-title section-title">
                        <span>About Us</span>
                        <h2>We are modern & special for designing</h2>
                        <div class="bar"></div>
                    </div>

                    <div class="about-text">
                        <div class="icon">
                            <i class="flaticon-quality"></i>
                        </div>
                        <h3>Best Software Company in the World</h3>
                        <p>We provide marketing services to startups and small businesses to looking for a partner of their digital media, design & development, lead generation and communications requirents. We work with you, not for you. Although we have a great resources.</p>
                        <p>We are an experienced and talented team of passionate consultants who live and breathe search engine marketing.</p>

                        <div class="signature">
                            <img src="assets/img/signature.png" alt="signature">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-circle-img1">
        <img src="assets/img/shape-img1.png" alt="image">
    </div>
</section>
<!-- End About Area -->

<div class="separate">
    <div class="br-line"></div>
</div>

<!-- Start Process Area -->
<section class="process-section uk-process uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title uk-text-center">
            <span>Process</span>
            <h2>Easy ways to get ready your work</h2>
            <div class="bar"></div>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-4@m uk-child-width-1-2@s">
            <div class="item">
                <div class="single-process-box">
                    <div class="icon">
                        <i uk-icon="receiver"></i>
                    </div>
                    <h3>Contact Us First</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                </div>
            </div>

            <div class="item">
                <div class="single-process-box">
                    <div class="icon">
                        <i uk-icon="cog"></i>
                    </div>
                    <h3>Consult With Us</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                </div>
            </div>

            <div class="item">
                <div class="single-process-box">
                    <div class="icon">
                        <i uk-icon="cart"></i>
                    </div>
                    <h3>Place Order</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                </div>
            </div>

            <div class="item">
                <div class="single-process-box">
                    <div class="icon">
                        <i uk-icon="home"></i>
                    </div>
                    <h3>Give Us Payment!</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                </div>
            </div>

            <div class="item">
                <div class="process-arrow-icon">
                    <img src="assets/img/arrow.png" alt="image">
                </div>
            </div>
        </div>
    </div>

    <div class="shape-circle-img2">
        <img src="assets/img/shape-img2.png" alt="image">
    </div>
</section>
<!-- End Process Area -->

<div class="separate">
    <div class="br-line"></div>
</div>

<!-- Start Feedback Area -->
<section id="testimonials" class="feedback-section uk-process uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title uk-text-center">
            <span>Testimonials</span>
            <h2>We have a great review from our clients</h2>
            <div class="bar"></div>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="item">
                <div class="single-feedback-item">
                    <i class="flaticon-quote"></i>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <div class="client-info">
                        <img src="assets/img/client1.png" alt="image">
                        <h3>Jason Statham</h3>
                        <span>CTO at Envato</span>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="single-feedback-item">
                    <i class="flaticon-quote"></i>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <div class="client-info">
                        <img src="assets/img/client2.png" alt="image">
                        <h3>Sarah Taylor</h3>
                        <span>CTO at EnvyTheme</span>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="single-feedback-item">
                    <i class="flaticon-quote"></i>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <div class="client-info">
                        <img src="assets/img/client3.png" alt="image">
                        <h3>Alister Cook</h3>
                        <span>CTO at Envato</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-circle-img2">
        <img src="assets/img/shape-img2.png" alt="image">
    </div>
</section>
<!-- End Feedback Area -->

<div class="separate">
    <div class="br-line"></div>
</div>

<!-- Start Project Area -->
<section id="project" class="project-section uk-project uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title uk-text-center">
            <span>Our Completed Projects</span>
            <h2>Recent Projects</h2>
            <div class="bar"></div>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="item">
                <div class="single-project-box">
                    <a routerLink="/project-details" class="project-img">
                        <img src="assets/img/project1.jpg" alt="image">
                    </a>

                    <div class="project-content">
                        <h3><a routerLink="/project-details">Development</a></h3>
                        <ul>
                            <li><a href="#">Web</a></li>
                            <li><a href="#">Mobile</a></li>
                        </ul>
                        <a routerLink="/project-details" class="details-btn"><i uk-icon="plus"></i></a>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="single-project-box">
                    <a routerLink="/project-details" class="project-img">
                        <img src="assets/img/project2.jpg" alt="image">
                    </a>

                    <div class="project-content">
                        <h3><a routerLink="/project-details">Architecture</a></h3>
                        <ul>
                            <li><a href="#">Home</a></li>
                            <li><a href="#">Bridge</a></li>
                        </ul>
                        <a routerLink="/project-details" class="details-btn"><i uk-icon="plus"></i></a>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="single-project-box">
                    <a routerLink="/project-details" class="project-img">
                        <img src="assets/img/project3.jpg" alt="image">
                    </a>

                    <div class="project-content">
                        <h3><a routerLink="/project-details">UX/UI Design</a></h3>
                        <ul>
                            <li><a href="#">Web</a></li>
                            <li><a href="#">Mobile</a></li>
                        </ul>
                        <a routerLink="/project-details" class="details-btn"><i uk-icon="plus"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-circle-img1">
        <img src="assets/img/shape-img1.png" alt="image">
    </div>
</section>
<!-- End Project Area -->

<!-- Start Funfacts Area -->
<section class="funfacts-area uk-section uk-funfacts">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-4@m uk-child-width-1-2@s">
            <div class="item">
                <div class="single-funfacts">
                    <div class="icon">
                        <i uk-icon="users"></i>
                    </div>
                    <h3 class="odometer" data-count="1800">00</h3>
                    <p>Satisfied Clients</p>
                </div>
            </div>

            <div class="item">
                <div class="single-funfacts">
                    <div class="icon">
                        <i uk-icon="heart"></i>
                    </div>
                    <h3 class="odometer" data-count="2500">00</h3>
                    <p>Completed Projects</p>
                </div>
            </div>

            <div class="item">
                <div class="single-funfacts">
                    <div class="icon">
                        <i uk-icon="user"></i>
                    </div>
                    <h3 class="odometer" data-count="250">00</h3>
                    <p>Team Members</p>
                </div>
            </div>

            <div class="item">
                <div class="single-funfacts">
                    <div class="icon">
                        <i uk-icon="unlock"></i>
                    </div>
                    <h3 class="odometer" data-count="150">00</h3>
                    <p>Award Winners</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Funfacts Area -->

<!-- Start Blog Area -->
<section id="blog" class="blog-area uk-blog uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title uk-text-center">
            <span>Our Company Blog</span>
            <h2>Latest News</h2>
            <div class="bar"></div>
        </div>

        <div class="blog-slides owl-carousel owl-theme">
            <div class="single-blog-post">
                <div class="blog-post-image">
                    <a routerLink="/blog-details">
                        <img src="assets/img/blog1.jpg" alt="image">
                    </a>
                </div>

                <div class="blog-post-content">
                    <span class="date">25 April</span>
                    <h3><a routerLink="/blog-details">The 13 Best Time Tracking Apps of 2023</a></h3>
                    <a routerLink="/blog-details" class="read-more">Read More</a>
                </div>
            </div>

            <div class="single-blog-post">
                <div class="blog-post-image">
                    <a routerLink="/blog-details">
                        <img src="assets/img/blog2.jpg" alt="image">
                    </a>
                </div>

                <div class="blog-post-content">
                    <span class="date">26 April</span>
                    <h3><a routerLink="/blog-details">11 Tools to Help You Easily Create Proposals</a></h3>
                    <a routerLink="/blog-details" class="read-more">Read More</a>
                </div>
            </div>

            <div class="single-blog-post">
                <div class="blog-post-image">
                    <a routerLink="/blog-details">
                        <img src="assets/img/blog3.jpg" alt="image">
                    </a>
                </div>

                <div class="blog-post-content">
                    <span class="date">27 April</span>
                    <h3><a routerLink="/blog-details">The Outlook for Digital Agencies in 4 Charts</a></h3>
                    <a routerLink="/blog-details" class="read-more">Read More</a>
                </div>
            </div>

            <div class="single-blog-post">
                <div class="blog-post-image">
                    <a routerLink="/blog-details">
                        <img src="assets/img/blog1.jpg" alt="image">
                    </a>
                </div>

                <div class="blog-post-content">
                    <span class="date">25 April</span>
                    <h3><a routerLink="/blog-details">The 13 Best Time Tracking Apps of 2023</a></h3>
                    <a routerLink="/blog-details" class="read-more">Read More</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Blog Area -->

<!-- Start Partner Area -->
<div class="partner-area uk-section uk-padding-remove-top">
    <div class="uk-container">
        <div class="partner-slides owl-carousel owl-theme">
            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-one.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-two.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-three.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-four.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-five.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-six.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</div>
<!-- End Partner Area -->

<!-- Start Contact Area -->
<section id="contact" class="contact-section uk-contact">
    <div class="uk-container-expand">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="contact-image">
                    <img src="assets/img/contact-image.jpg" alt="image">

                    <div class="contact-info">
                        <h3>N°999 de l'avenue rn 39, quartier joli site<br />Commune de Manika, à Kolwezi,</h3>
                        <ul>
                            <li>Email: <a href="mailto:info@makudigital.com">info@makudigital.com</a></li>
                            <li>Phone: <a href="tel:+243858585822">+243858585822</a></li>
<!--                            <li>Fax: <a href="tel:+123849457">+ (123) 849 457</a></li>-->
                        </ul>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="contact-form">
                    <div class="uk-section-title section-title">
                        <span>Let’s Talk</span>
                        <h2>Want to work with our team?</h2>
                        <div class="bar"></div>
                    </div>

                    <form id="contactForm">
                        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
                            <div class="item uk-margin">
                                <input type="text" class="uk-input" name="name" id="name" placeholder="Name">
                            </div>

                            <div class="item uk-margin">
                                <input type="email" class="uk-input" name="email" id="email" placeholder="Email">
                            </div>

                            <div class="item uk-margin">
                                <input type="text" class="uk-input" placeholder="Phone">
                            </div>

                            <div class="item uk-margin">
                                <input type="text" class="uk-input" name="subject" id="subject" placeholder="Subject">
                            </div>
                        </div>

                        <div class="item">
                            <textarea name="message" class="uk-textarea" id="message" cols="30" rows="4" placeholder="Your Message"></textarea>
                        </div>

                        <button type="submit" class="uk-button uk-button-default">Submit Message</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Contact Area -->
