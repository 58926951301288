<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>À propos de nous</h1>
        <ul>
            <li><a routerLink="/">Accueil</a></li>
            <li>À propos de nous</li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="uk-about about-area uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="about-content">
                    <div class="uk-section-title section-title">
                        <span>À PROPOS DE NOUS</span>
                        <h2>Faite Vos Publicités Sous Une Autre Dimension</h2>
                        <div class="bar"></div>
                    </div>

                    <div class="about-text">
                        <div class="icon">
                            <i class="flaticon-quality"></i>
                        </div>
                        <h3>Meilleure agence numérique au Katanga</h3>
                        <p>Chez Maku-Digital, notre approche de la publicité sur écran électronique et du marketing numérique repose sur
                            un processus dynamique et axé sur les résultats.</p>
                        <p>Nous commençons par comprendre vos objectifs, votre
                            public cible et votre marché. Ensuite, notre équipe d'experts chevronnés élabore des stratégies personnalisées
                            qui intègrent de manière transparente un contenu créatif et une technologie de pointe.</p>
<!--                        <p>We are an experienced and talented team of passionate consultants who live and breathe search engine marketing.</p>-->

                        <div class="signature">
                            <img src="assets/img/signature.webp" alt="signature">
                        </div>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="about-img">
                    <img src="assets/img/about1.webp" class="about-img1" alt="about-img">
                    <img src="assets/img/DRIU.webp" height="425" width="310"  class="about-img2" alt="about-img">
                    <img src="assets/img/1.png" class="shape-img" alt="shape">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Area -->

<div class="separate">
    <div class="br-line"></div>
</div>

<!-- Start Feedback Area -->
<section id="clients" class="feedback-area uk-section uk-feedback">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="feedback-img">
                    <img src="assets/img/women.webp" alt="image">

                    <img src="assets/img/1.png" class="shape-img" alt="image">

                    <a href="https://www.youtube.com/watch?v=X2kIR5t35uk" class="video-btn popup-youtube"><i class="flaticon-multimedia"></i> Voir la vidéo</a>
                </div>
            </div>

            <div class="item">
                <div class="feedback-inner">
                    <div class="uk-section-title section-title">
                        <span>Ce que les clients disent de nous</span>
                        <h2>Nos témoignages</h2>
                        <div class="bar"></div>
                    </div>

                    <div class="feedback-slides owl-carousel owl-theme">
                        <div class="single-feedback">
                            <i class="flaticon-quote"></i>
                            <p>La DRLU est heureuse du partenariat naissant avec MAKU DIGITAL qui lui rassure une extension progressive de ses activités pour une sensibilisation numérique sécurisée 7/7 et 24/24.</p>

                            <div class="client">
                                <h3>Le Directeur de la DRLU</h3>
                                <span>Francis KALASSA MANIKA</span>
                            </div>
                        </div>

                        <div class="single-feedback">
                            <i class="flaticon-quote"></i>
                            <p>Le service d'affichage publicitaire de Maku Digital a changé la donne pour notre entreprise. Les panneaux sont placés à des endroits stratégiques et ont attiré l'attention des passants. Nous apprécions la créativité et le dévouement de leur équipe pour faire ressortir notre marque.</p>

                            <div class="client">
                                <h3>Nicole Klassen</h3>
                                <span>Founder at Noborders</span>
                            </div>
                        </div>

                        <div class="single-feedback">
                            <i class="flaticon-quote"></i>
                            <p>Nous utilisons les services d'affichage publicitaire de Maku Digital pour notre entreprise locale, et c'est une excellente expérience. L'équipe de Maku Digital nous a aidés à choisir des emplacements stratégiques pour nos panneaux d'affichage et nous avons constaté une augmentation notable du trafic piétonnier. Les designs créatifs et les messages clairs sur les panneaux ont permis de capter l'attention de notre publique cible.</p>

                            <div class="client">
                                <h3>Maman Fifi Masuka Saini</h3>
                                <span>Gouverneur du Lualaba</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Feedback Area -->

<!-- Start Partner Area -->
<div class="partner-area uk-section uk-padding-remove-top">
    <div class="uk-container">
        <div class="partner-slides owl-carousel owl-theme">
            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-one.webp" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-two.webp" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-three.webp" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-four.webp" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-five.webp" alt="image">
                </a>
            </div>

            <!-- <div class="item">
                <a href="#">
                    <img src="assets/img/partner-six.png" alt="image">
                </a>
            </div> -->
        </div>
    </div>
</div>
<!-- End Partner Area -->

<!-- Start Team Area -->
<section id="team" class="team-area uk-team uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title">
            <span>Meet Our Experts</span>
            <h2>Notre équipe</h2>
            <div class="bar"></div>

            <a href="#" class="uk-button uk-button-default">Voir tout</a>
        </div>
    </div>

    <div class="team-slides owl-carousel owl-theme">
        <div class="single-team">
            <ul class="team-social">
                <li><a href="#"><i class="flaticon-logo"></i></a></li>
                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
            </ul>

            <img src="assets/img/team1.webp" alt="image">

            <div class="team-content">
                <h3>David Matanda</h3>
                <span>Directeur financier</span>
            </div>
        </div>

        <div class="single-team">
            <ul class="team-social">
                <li><a href="#"><i class="flaticon-logo"></i></a></li>
                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
            </ul>

            <img src="assets/img/team2.webp" alt="image">

            <div class="team-content">
                <h3>Jules Kyebo</h3>
                <span>Senior IT Specialist</span>
            </div>
        </div>

        <div class="single-team">
            <ul class="team-social">
                <li><a href="#"><i class="flaticon-logo"></i></a></li>
                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
            </ul>

            <img src="assets/img/team3.webp" alt="image">

            <div class="team-content">
                <h3>Gloria Kasala </h3>
                <span>Admin</span>
            </div>
        </div>

        <div class="single-team">
            <ul class="team-social">
                <li><a href="#"><i class="flaticon-logo"></i></a></li>
                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
            </ul>

            <img src="assets/img/team6.webp" alt="image">

            <div class="team-content">
                <h3>Kazadi Mushimba John Scotty</h3>
                <span>Soutien à la conception Graphique, à la Photographie et à la Vidéographie</span>
            </div>
        </div>

        <div class="single-team">
            <ul class="team-social">
                <li><a href="#"><i class="flaticon-logo"></i></a></li>
                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
            </ul>

            <img src="assets/img/team4.webp" alt="image">

            <div class="team-content">
                <h3>Fanny Kunda</h3>
                <span>Directeur General</span>
            </div>
        </div>

        <div class="single-team">
            <ul class="team-social">
                <li><a href="#"><i class="flaticon-logo"></i></a></li>
                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
            </ul>

            <img src="assets/img/team5.webp" alt="image">

            <div class="team-content">
                <h3>Ariane-Nday</h3>
                <span>HR</span>
            </div>
        </div>
    </div>
</section>
<!-- End Team Area -->

<!-- Start Subscribe Area -->
<section class="subscribe-area uk-section uk-subscribe bg-gray">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <h3>S'abonner à la newsletter</h3>
            </div>

            <div class="item">
                <form class="newsletter-form">
                    <input type="email" class="uk-input" placeholder="name@gmail.com" name="EMAIL" required autocomplete="off">

                    <button type="submit" class="uk-button uk-button-default">S'abonner</button>
                </form>
            </div>
        </div>
    </div>

    <div class="shape"><img src="assets/img/footer-shape1.png" alt="shape"></div>
</section>
<!-- End Subscribe Area -->
