<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Contact</h1>
        <ul>
            <li><a routerLink="/">Home</a></li>
            <li>Contact</li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start Contact Area -->
<section id="contact" class="contact-area uk-contact uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title">
            <span>PARLONS-EN</span>
            <h2>Prenez Contact avec Nous</h2>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="map-img">
                    <img src="assets/img/map.png" alt="map">

                    <div class="location uk-location1">
                        <a href="#" class="active">
                            <div class="location-info">
                                <h5>Kolwezi</h5>
                                <span>N°999 de l'avenue rn 39, quartier</span>
                                <span> joli site Commune de Manika, à Kolwezi</span>
                            </div>
                        </a>
                    </div>

                    <div class="location uk-location2">
                        <a href="#">
                            <div class="location-info">
                                <h5>Lubumbashi</h5>
                                <span>To be confirmed</span>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <div class="item">
                <form id="contactForm">
                    <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
                        <div class="item uk-margin">
                            <input type="text" class="uk-input" name="name" id="name" placeholder="Nom">
                        </div>

                        <div class="item uk-margin">
                            <input type="email" class="uk-input" name="email" id="email" placeholder="Couriel">
                        </div>

                        <div class="item uk-margin">
                            <input type="text" class="uk-input" placeholder="Phone">
                        </div>

                        <div class="item uk-margin">
                            <input type="text" class="uk-input" name="subject" id="subject" placeholder="Votre sujet ici">
                        </div>
                    </div>

                    <div class="item">
                        <textarea name="message" class="uk-textarea" id="message" cols="30" rows="4" placeholder="Votre message"></textarea>
                    </div>

                    <button type="submit" class="uk-button uk-button-default">Soumettre un message</button>
                </form>
            </div>
        </div>
    </div>
</section>
<!-- End Contact Area -->
