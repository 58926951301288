<!-- Start Marketing Banner Area -->
<div class="uk-marketing-banner marketing-main-banner-area">
    <div class="uk-container">
        <div class="marketing-banner-content">
            <span>Welcome Marketing Agency</span>
            <h1>Start <b>Marketing</b> Journey Today</h1>
            <p>Pellentesque in ipsum id orci porta dapibus vivamus suscipit tortor eget felis porttitor volutpat cras ultricies ligula sed magna dictum porta.</p>

            <ul class="banner-btn-list">
                <li>
                    <a href="#" class="uk-button uk-button-default">Get Started</a>
                </li>
                <li>
                    <a href="#" class="uk-button uk-button-default">About Us</a>
                </li>
            </ul>
        </div>
    </div>
</div>
<!-- End Marketing Banner Area -->

<!-- Start Creative Partner Area -->
<div class="uk-creative-partner creative-partner-area with-bg-color ptb-100">
    <div class="uk-container">
        <div class="creative-partner-slides owl-carousel owl-theme">
            <div class="creative-partner-item without-border">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner1.png" alt="image">
                </a>
            </div>

            <div class="creative-partner-item without-border">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner2.png" alt="image">
                </a>
            </div>

            <div class="creative-partner-item without-border">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner3.png" alt="image">
                </a>
            </div>

            <div class="creative-partner-item without-border">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner4.png" alt="image">
                </a>
            </div>

            <div class="creative-partner-item without-border">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner5.png" alt="image">
                </a>
            </div>

            <div class="creative-partner-item without-border">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner6.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</div>
<!-- End Creative Partner Area -->

<!-- Start Marketing About Area -->
<div class="uk-creative-about marketing-about-area ptb-100">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item uk-flex-middle">
                <div class="marketing-about-content top-zero">
                    <span>About Us</span>
                    <h3>We Are Marketing Expert You Need For Your Business</h3>
                    <p>We provide marketing services to startups and small businesses to looking for a partner of their digital media, design & development, lead generation and communications requirents. We work with you, not for you. Although we have a great resources.</p>
                    <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
                        <div class="item">
                            <div class="about-inner-card">
                                <h5>Our Mission</h5>
                                <p>We provide marketing services to startups and small businesses to looking for a partner.</p>
                            </div>
                        </div>

                        <div class="item">
                            <div class="about-inner-card">
                                <h5>Our Vision</h5>
                                <p>We provide marketing services to startups and small businesses to looking for a partner.</p>
                            </div>
                        </div>
                    </div>
                    <div class="about-btn">
                        <a href="#" class="uk-button uk-button-default">Know More About Us</a>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="marketing-about-image style-two">
                    <img src="assets/img/home-nine/about.jpg" alt="image">

                    <div class="content">
                        <h4>15</h4>
                        <b>Years Experience</b>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Marketing About Area -->

<!-- Start Creative Services Area -->
<div class="uk-creative-services creative-services-area with-bg-image pb-100">
    <div class="uk-container pb-100">
        <div class="creative-funfacts-area pt-100 pb-70">
            <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-4@m uk-child-width-1-2@s">
                <div class="item">
                    <div class="single-creative-funfacts">
                        <h3>
                            <span class="odometer" data-count="1800">00</span>
                            <span class="sign">+</span>
                        </h3>
                        <p>Satisfied Clients</p>
                    </div>
                </div>

                <div class="item">
                    <div class="single-creative-funfacts">
                        <h3>
                            <span class="odometer" data-count="2500">00</span>
                            <span class="sign">+</span>
                        </h3>
                        <p>Completed Projects</p>
                    </div>
                </div>

                <div class="item">
                    <div class="single-creative-funfacts">
                        <h3>
                            <span class="odometer" data-count="250">00</span>
                            <span class="sign">+</span>
                        </h3>
                        <p>Team Members</p>
                    </div>
                </div>

                <div class="item">
                    <div class="single-creative-funfacts">
                        <h3>
                            <span class="odometer" data-count="15">00</span>
                            <span class="sign">+</span>
                        </h3>
                        <p>Award Winners</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="uk-container">
        <div class="section-title-with-big-text top-zero">
            <span>OUR SERVICES</span>
            <h2>All The Services That We Provide To Meet The Business Needs Of The Clients</h2>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="uk-item">
                <div class="creative-services-box white-box">
                    <div class="content">
                        <div class="number">
                            <span>01</span>
                        </div>
                        <h3>
                            <a href="single-services.html">Marketing Solutions</a>
                        </h3>
                        <p>Vivamus suscipit tortor eget felis porttitor volutpat sed porttitor lectus nibh.</p>
                    </div>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-services-box white-box">
                    <div class="content">
                        <div class="number">
                            <span>02</span>
                        </div>
                        <h3>
                            <a href="single-services.html">Mobile Applications</a>
                        </h3>
                        <p>Vivamus suscipit tortor eget felis porttitor volutpat sed porttitor lectus nibh.</p>
                    </div>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-services-box white-box">
                    <div class="content">
                        <div class="number">
                            <span>03</span>
                        </div>
                        <h3>
                            <a href="single-services.html">Web Development</a>
                        </h3>
                        <p>Vivamus suscipit tortor eget felis porttitor volutpat sed porttitor lectus nibh.</p>
                    </div>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-services-box white-box">
                    <div class="content">
                        <div class="number">
                            <span>04</span>
                        </div>
                        <h3>
                            <a href="single-services.html">E-commerce</a>
                        </h3>
                        <p>Vivamus suscipit tortor eget felis porttitor volutpat sed porttitor lectus nibh.</p>
                    </div>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-services-box white-box">
                    <div class="content">
                        <div class="number">
                            <span>05</span>
                        </div>
                        <h3>
                            <a href="single-services.html">Business Analytics</a>
                        </h3>
                        <p>Vivamus suscipit tortor eget felis porttitor volutpat sed porttitor lectus nibh.</p>
                    </div>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-services-box white-box">
                    <div class="content">
                        <div class="number">
                            <span>06</span>
                        </div>
                        <h3>
                            <a href="single-services.html">Creative Solutions</a>
                        </h3>
                        <p>Vivamus suscipit tortor eget felis porttitor volutpat sed porttitor lectus nibh.</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="view-all-services-btn">
            <a href="services.html">View Our All Services</a>
        </div>
    </div>
</div>
<!-- End Creative Services Area -->

<!-- Start Creative Projects Area -->
<div class="uk-creative-projects creative-projects-area with-bg-color ptb-100">
    <div class="uk-container">
        <div class="section-title-with-big-text top-zero">
            <span>OUR COMPLETED PROJECTS</span>
            <h2>Take A Look At All The Projects That We Have Completed For The Client</h2>
        </div>
    </div>

    <div class="uk-container-expand">
        <div class="creative-projects-slides-three owl-carousel owl-theme">
            <div class="creative-single-projects">
                <div class="projects-image">
                    <a href="single-project.html">
                        <img src="assets/img/home-seven/projects/projects1.jpg" alt="image">
                    </a>
                </div>
                <div class="projects-content">
                    <span>Marketing</span>
                    <h3>
                        <a href="single-project.html">Affiliate Marketing</a>
                    </h3>
                </div>
                <div class="projects-icon">
                    <a href="single-project.html"><i class="flaticon-right"></i></a>
                </div>
            </div>

            <div class="creative-single-projects">
                <div class="projects-image">
                    <a href="single-project.html">
                        <img src="assets/img/home-seven/projects/projects2.jpg" alt="image">
                    </a>
                </div>
                <div class="projects-content">
                    <span>Mobile App</span>
                    <h3>
                        <a href="single-project.html">Mobile Applications</a>
                    </h3>
                </div>
                <div class="projects-icon">
                    <a href="single-project.html"><i class="flaticon-right"></i></a>
                </div>
            </div>

            <div class="creative-single-projects">
                <div class="projects-image">
                    <a href="single-project.html">
                        <img src="assets/img/home-seven/projects/projects3.jpg" alt="image">
                    </a>
                </div>
                <div class="projects-content">
                    <span>Hosting</span>
                    <h3>
                        <a href="single-project.html">Web Hosting</a>
                    </h3>
                </div>
                <div class="projects-icon">
                    <a href="single-project.html"><i class="flaticon-right"></i></a>
                </div>
            </div>

            <div class="creative-single-projects">
                <div class="projects-image">
                    <a href="single-project.html">
                        <img src="assets/img/home-seven/projects/projects4.jpg" alt="image">
                    </a>
                </div>
                <div class="projects-content">
                    <span>Development</span>
                    <h3>
                        <a href="single-project.html">Web Development</a>
                    </h3>
                </div>
                <div class="projects-icon">
                    <a href="single-project.html"><i class="flaticon-right"></i></a>
                </div>
            </div>

            <div class="creative-single-projects">
                <div class="projects-image">
                    <a href="single-project.html">
                        <img src="assets/img/home-seven/projects/projects5.jpg" alt="image">
                    </a>
                </div>
                <div class="projects-content">
                    <span>Bridge</span>
                    <h3>
                        <a href="single-project.html">E-commerce</a>
                    </h3>
                </div>
                <div class="projects-icon">
                    <a href="single-project.html"><i class="flaticon-right"></i></a>
                </div>
            </div>

            <div class="creative-single-projects">
                <div class="projects-image">
                    <a href="single-project.html">
                        <img src="assets/img/home-seven/projects/projects1.jpg" alt="image">
                    </a>
                </div>
                <div class="projects-content">
                    <span>Marketing</span>
                    <h3>
                        <a href="single-project.html">Affiliate Marketing</a>
                    </h3>
                </div>
                <div class="projects-icon">
                    <a href="single-project.html"><i class="flaticon-right"></i></a>
                </div>
            </div>

            <div class="creative-single-projects">
                <div class="projects-image">
                    <a href="single-project.html">
                        <img src="assets/img/home-seven/projects/projects2.jpg" alt="image">
                    </a>
                </div>
                <div class="projects-content">
                    <span>Mobile App</span>
                    <h3>
                        <a href="single-project.html">Mobile Applications</a>
                    </h3>
                </div>
                <div class="projects-icon">
                    <a href="single-project.html"><i class="flaticon-right"></i></a>
                </div>
            </div>

            <div class="creative-single-projects">
                <div class="projects-image">
                    <a href="single-project.html">
                        <img src="assets/img/home-seven/projects/projects3.jpg" alt="image">
                    </a>
                </div>
                <div class="projects-content">
                    <span>Hosting</span>
                    <h3>
                        <a href="single-project.html">Web Hosting</a>
                    </h3>
                </div>
                <div class="projects-icon">
                    <a href="single-project.html"><i class="flaticon-right"></i></a>
                </div>
            </div>

            <div class="creative-single-projects">
                <div class="projects-image">
                    <a href="single-project.html">
                        <img src="assets/img/home-seven/projects/projects4.jpg" alt="image">
                    </a>
                </div>
                <div class="projects-content">
                    <span>Development</span>
                    <h3>
                        <a href="single-project.html">Web Development</a>
                    </h3>
                </div>
                <div class="projects-icon">
                    <a href="single-project.html"><i class="flaticon-right"></i></a>
                </div>
            </div>

            <div class="creative-single-projects">
                <div class="projects-image">
                    <a href="single-project.html">
                        <img src="assets/img/home-seven/projects/projects5.jpg" alt="image">
                    </a>
                </div>
                <div class="projects-content">
                    <span>Bridge</span>
                    <h3>
                        <a href="single-project.html">E-commerce</a>
                    </h3>
                </div>
                <div class="projects-icon">
                    <a href="single-project.html"><i class="flaticon-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Creative Projects Area -->

<!-- Start Creative Pricing Area -->
<section class="uk-creative-pricing creative-pricing-area pt-100 pb-70">
    <div class="uk-container">
        <div class="section-title-with-big-text top-zero">
            <span>OUR PRICES</span>
            <h2>Our Flexible Pricing Plan</h2>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="uk-item">
                <div class="creative-pricing-box with-bg-image">
                    <div class="pricing-header">
                        <h3>Starter Plan</h3>
                    </div>

                    <div class="price">
                        $49.99 <span>Per Month</span>
                    </div>

                    <ul class="features">
                        <li><i class='bx bx-check'></i> 15GB Bandwidth Internet</li>
                        <li><i class='bx bx-check'></i> Business & Financ Analysing</li>
                        <li><i class='bx bx-check'></i> 30 Social Media Reviews</li>
                        <li><i class='bx bx-check'></i> Customer Managemet</li>
                        <li><i class='bx bx-check'></i> Weekly Reports</li>
                        <li><i class='bx bx-check'></i> 24/7 Support</li>
                    </ul>

                    <a href="#" class="uk-button uk-button-default">Choose Plan</a>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-pricing-box with-bg-image">
                    <div class="pricing-header">
                        <h3>Advance Plan</h3>
                    </div>

                    <div class="price">
                        $59.99 <span>Per Month</span>
                    </div>

                    <ul class="features">
                        <li><i class='bx bx-check'></i> 15GB Bandwidth Internet</li>
                        <li><i class='bx bx-check'></i> Business & Financ Analysing</li>
                        <li><i class='bx bx-check'></i> 30 Social Media Reviews</li>
                        <li><i class='bx bx-check'></i> Customer Managemet</li>
                        <li><i class='bx bx-check'></i> Weekly Reports</li>
                        <li><i class='bx bx-check'></i> 24/7 Support</li>
                    </ul>

                    <a href="#" class="uk-button uk-button-default">Choose Plan</a>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-pricing-box with-bg-image">
                    <div class="pricing-header">
                        <h3>Premium Plan</h3>
                    </div>

                    <div class="price">
                        $99.99 <span>Per Month</span>
                    </div>

                    <ul class="features">
                        <li><i class='bx bx-check'></i> 15GB Bandwidth Internet</li>
                        <li><i class='bx bx-check'></i> Business & Financ Analysing</li>
                        <li><i class='bx bx-check'></i> 30 Social Media Reviews</li>
                        <li><i class='bx bx-check'></i> Customer Managemet</li>
                        <li><i class='bx bx-check'></i> Weekly Reports</li>
                        <li><i class='bx bx-check'></i> 24/7 Support</li>
                    </ul>

                    <a href="#" class="uk-button uk-button-default">Choose Plan</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Creative Pricing Area -->

<!-- Start Creative Video Area -->
<section class="uk-creative-video creative-video-area">
    <div class="uk-container">
        <div class="creative-video-image">
            <img src="assets/img/home-nine/video.jpg" alt="image">
            <a href="https://www.youtube.com/watch?v=PWvPbGWVRrU" class="video-btn popup-youtube"><i class='bx bx-play'></i></a>
        </div>
    </div>
</section>
<!-- End Creative Video Area -->

<!-- Start Creative Process Area -->
<section class="uk-creative-process creative-process-area with-bg-color pt-100 pb-70">
    <div class="uk-container">
        <div class="uk-text-center uk-flex-middle" uk-grid>
            <div class="uk-width-1-4">
                <div class="section-title-with-big-text top-zero">
                    <span>PROCESS</span>
                    <h2>Easy Ways To Get Ready Your Work</h2>
                </div>
            </div>

            <div class="uk-width-3-4">
                <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
                    <div class="uk-item">
                        <div class="creative-process-card with-bg-color">
                            <div class="icon">
                                <i class="flaticon-project"></i>
                            </div>
                            <h3>
                                <a href="#">Project Research</a>
                            </h3>
                            <p>Risus commodo maecenas accumsan lacus vel facilisis consectetur adipiscing.</p>
                        </div>
                    </div>

                    <div class="uk-item">
                        <div class="creative-process-card with-bg-color">
                            <div class="icon">
                                <i class="flaticon-ux-design"></i>
                            </div>
                            <h3>
                                <a href="#">Project Design</a>
                            </h3>
                            <p>Risus commodo maecenas accumsan lacus vel facilisis consectetur adipiscing.</p>
                        </div>
                    </div>

                    <div class="uk-item">
                        <div class="creative-process-card with-bg-color">
                            <div class="icon">
                                <i class="flaticon-think"></i>
                            </div>
                            <h3>
                                <a href="#">Project Launch</a>
                            </h3>
                            <p>Risus commodo maecenas accumsan lacus vel facilisis consectetur adipiscing.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Creative Process Area -->

<!-- Start Creative Team Area -->
<section class="uk-creative-team creative-team-area pt-100 pb-70">
    <div class="uk-container">
        <div class="section-title-with-big-text top-zero">
            <span>MEET OUR EXPERTS</span>
            <h2>Meet Our Experts Team Member</h2>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-4@m uk-child-width-1-2@s">
            <div class="item">
                <div class="creative-team-box without-border-radius">
                    <div class="team-image">
                        <img src="assets/img/home-nine/team/team1.jpg" alt="image">

                        <ul class="team-social">
                            <li><a href="#"><i class="flaticon-logo"></i></a></li>
                            <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                            <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
                        </ul>
                    </div>
                    <div class="team-content">
                        <h3>Josh Buttler</h3>
                        <span>Photographer</span>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="creative-team-box without-border-radius">
                    <div class="team-image">
                        <img src="assets/img/home-nine/team/team2.jpg" alt="image">

                        <ul class="team-social">
                            <li><a href="#"><i class="flaticon-logo"></i></a></li>
                            <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                            <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
                        </ul>
                    </div>
                    <div class="team-content">
                        <h3>Steve Eva</h3>
                        <span>Designer</span>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="creative-team-box without-border-radius">
                    <div class="team-image">
                        <img src="assets/img/home-nine/team/team3.jpg" alt="image">

                        <ul class="team-social">
                            <li><a href="#"><i class="flaticon-logo"></i></a></li>
                            <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                            <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
                        </ul>
                    </div>
                    <div class="team-content">
                        <h3>Brand Camus</h3>
                        <span>Web Developer</span>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="creative-team-box without-border-radius">
                    <div class="team-image">
                        <img src="assets/img/home-nine/team/team4.jpg" alt="image">

                        <ul class="team-social">
                            <li><a href="#"><i class="flaticon-logo"></i></a></li>
                            <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                            <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
                        </ul>
                    </div>
                    <div class="team-content">
                        <h3>Olivia Smith</h3>
                        <span>Front-End Developer</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Creative Team Area -->

<!-- Start Creative Reviews Area -->
<div class="uk-creative-reviews creative-reviews-area pb-100">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="creative-reviews-img">
                    <img src="assets/img/home-nine/reviews.jpg" alt="image">

                    <div class="icon">
                        <i class="flaticon-quote"></i>
                    </div>
                </div>
            </div>

            <div class="item uk-flex-middle">
                <div class="creative-reviews-slides owl-carousel owl-theme">
                    <div class="creative-reviews-card">
                        <ul class="rating">
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                        </ul>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                        <div class="client">
                            <h3>Jason Statham</h3>
                            <span>Founder at Envato</span>
                        </div>
                    </div>

                    <div class="creative-reviews-card">
                        <ul class="rating">
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                        </ul>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                        <div class="client">
                            <h3>Jason Statham</h3>
                            <span>Founder at Envato</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Creative Reviews Area -->

<!-- Start Creative News Area -->
<section class="uk-creative-news creative-news-area pt-100 pb-70">
    <div class="uk-container">
        <div class="section-title-with-big-text top-zero">
            <span>Latest News</span>
            <h2>Read Our Latest News</h2>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="uk-item">
                <div class="creative-news-box">
                    <div class="news-image">
                        <a href="single-blog.html">
                            <img src="assets/img/home-nine/news/news1.jpg" alt="image">
                        </a>
                    </div>
                    <div class="news-content">
                        <ul class="meta">
                            <li><a href="#">Web</a></li>
                            <li>02-02-2022</li>
                        </ul>
                        <h3>
                            <a href="single-blog.html">University Admissions Could Face Emergency Controls</a>
                        </h3>
                        <div class="info">
                            <img src="assets/img/client1.png" alt="image">

                            <div class="title">
                                <h4>By <a href="#">Burnett</a></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-news-box">
                    <div class="news-image">
                        <a href="single-blog.html">
                            <img src="assets/img/home-nine/news/news2.jpg" alt="image">
                        </a>
                    </div>
                    <div class="news-content">
                        <ul class="meta">
                            <li><a href="#">Development</a></li>
                            <li>02-02-2022</li>
                        </ul>
                        <h3>
                            <a href="single-blog.html">How To Create A ReactJS Image Lightbox Gallery?</a>
                        </h3>
                        <div class="info">
                            <img src="assets/img/client2.png" alt="image">

                            <div class="title">
                                <h4>By <a href="#">Jimmie</a></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-news-box">
                    <div class="news-image">
                        <a href="single-blog.html">
                            <img src="assets/img/home-nine/news/news3.jpg" alt="image">
                        </a>
                    </div>
                    <div class="news-content">
                        <ul class="meta">
                            <li><a href="#">Design</a></li>
                            <li>02-02-2022</li>
                        </ul>
                        <h3>
                            <a href="single-blog.html">Hide WooCommerce Products From Specific Categories</a>
                        </h3>
                        <div class="info">
                            <img src="assets/img/client3.png" alt="image">

                            <div class="title">
                                <h4>By <a href="#">Rodriguez</a></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Creative News Area -->

<!-- Start Creative Contact Area -->
<div class="uk-creative-contact creative-contact-area ptb-100">
    <div class="uk-container">
        <div class="section-title-with-big-text top-zero">
            <span>LET’S TALK</span>
            <h2>Want To Work With Our Team?</h2>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="creative-contact-image with-wrap-color">
                    <img src="assets/img/home-nine/contact.webp" alt="image">

                    <div class="info-content">
                        <h3>Our Office</h3>
                        <span>352/71 Second Street King Street Kingston United Kingdom</span>
                    </div>
                </div>
            </div>

            <div class="item uk-flex-middle">
                <form class="creative-contact-form">
                    <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
                        <div class="item uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Name</label>
                            <input type="text" class="uk-input" placeholder="Eugene trask">
                        </div>

                        <div class="item uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Email</label>
                            <input type="email" class="uk-input" placeholder="trask@gmail.com">
                        </div>

                        <div class="item uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Phone</label>
                            <input type="text" class="uk-input" placeholder="+1-541-754-3010">
                        </div>

                        <div class="item uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Subject</label>
                            <input type="text" class="uk-input" placeholder="Your subject here">
                        </div>
                    </div>

                    <div class="item">
                        <label class="uk-form-label" for="form-stacked-text">Your message</label>
                        <textarea class="uk-textarea" cols="30" rows="4" placeholder="Write your message here..."></textarea>
                    </div>

                    <div class="checkbox-boxes">
                        <label><input class="uk-checkbox" type="checkbox"> By Checking This, You Agree To Our <a href="#">Terms</a> And <a href="#">Privacy Policy</a></label>
                    </div>

                    <button type="submit" class="uk-button uk-button-default">Submit Message</button>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- End Creative Contact Area -->
